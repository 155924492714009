import React from "react";
import {
  Box,
  Stack,
  Text,
  OrderedList,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import Container from "components/ui/Container";
import Seo from "components/Seo";
import { BsFillDiamondFill } from "react-icons/bs";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Box py={10}>
      <Seo
        title="Privacy Policy"
        description="Privacy policy"
        keywords="interior design software, online house planner, online floor plan, home design software, floor planner software,  3D home design software, 3D house plan software, online interior design software, home remodeling software, online rendering software, 3D visualization tool, get design ideas, design gallery, brand gallery."
      />

      <Container fluid>
        <Stack spacing={6} color="gray.600">
          <Text
            textAlign="center"
            fontSize="4xl"
            textDecor="underline"
            color="black"
          >
            Privacy Policy
          </Text>

          <Text fontSize="md">
            Special tips:
            <br />
            We are fully aware of the importance of personal information to you
            and will do our best to protect your personal information security.
            Through this privacy policy, we hope to show you how we can collect,
            use, store, share and transfer your personal information when using
            aihouse platform, and the way we provide you with access, update,
            delete and protect such information. Please read and understand this
            privacy policy carefully before using aihouse platform, and use our
            products or services after confirming that you fully understand and
            agree to it. Once you start using aihouse platform, it means that
            you have fully understood and agreed with this policy, also you
            agreed that we collect, use, store and share your relevant
            information in accordance with this privacy policy. If you have any
            questions, comments or suggestions about the content of this policy,
            you may contact us through the contact information provided by this
            privacy policy.
          </Text>

          <OrderedList
            stylePosition="inside"
            styleType="upper-roman"
            type="I"
            fontSize="xl"
          >
            <ListItem>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              How do we collect and use your personal information
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    Scope of personal dataPersonal data refers to information
                    that can identifies personal identity when used alone or in
                    combination with other information, including but not
                    limited to:
                    <OrderedList stylePosition="inside">
                      <ListItem>
                        Personal data provided by you when you register your
                        aihouse platform account through{" "}
                        <a href="https//www.aihouseindia.com/">
                          https://www.aihouseindia.com/
                        </a>
                        ;
                      </ListItem>
                      <ListItem>
                        3Vjia receives and records your relevant information
                        because you visit and use https://www.aihouse.com/ web
                        pages or services, including but not limited to the
                        record of web page you visit, date and time of access,
                        browser information you use (type, time zone, plug-ins,
                        language, font, canvas image rendering hash value,
                        etc.), the mouse and keyboard operation information on
                        the page, software and hardware features, network link
                        information (IP address, port, network protocol, etc.),
                        and other data;
                      </ListItem>
                      <ListItem>
                        Personal data provided by you when you interact with us,
                        use the services or getting support and help;
                      </ListItem>
                      <ListItem>
                        Data obtained by 3Vjia from business partners or a third
                        parties through legal channels. For example, we will
                        obtain relevant data from public and commercial
                        third-party sources to support our services, if
                        permitted by low.In general, you can browse the public
                        information of 3Vjia website without providing personal
                        data. In order to provide you with services, 3Vjia will
                        protect, use and disclose your personal data in
                        accordance with the provisions of this privacy policy.
                        For the purpose of service, if you voluntary fill in, or
                        provide required registration name, certificate type and
                        certificate number, date of birth, nationality, email
                        address, telephone and other similar personal data
                        according to requirements, it means that you have
                        understood and accepted the collection, protection and
                        use of your personal data by 3Vjia in accordance with
                        this privacy policy.
                      </ListItem>
                    </OrderedList>
                  </ListItem>
                  <ListItem mt={3}>
                    Non-personal data rangeNon-personal data include but are not
                    limited to:
                    <OrderedList stylePosition="inside">
                      <ListItem>
                        The information released or published by you, when using
                        the services (including but not limited to forums)
                        provided by https://www.aihouse.com/;
                      </ListItem>
                      <ListItem>
                        The actions that 3Vjia has taken to you, including but
                        not limited to credit evaluation, measures to deal with
                        violations of laws and regulations or violations of
                        3Vjia policy, and other relevant information;
                      </ListItem>
                      <ListItem>
                        3Vjia may also collect and use non-identifying data.
                        Non-identifying data refers to data that cannot be used
                        solely to confirm individual's identification. The
                        purpose of collecting this data by 3Vjia is to
                        understand how users use its websites, products and
                        services. In this way, 3Vjia can improve its services
                        and better meet customer needs. 3Vjia may decide on its
                        own to collect, use, process, transfer or disclose
                        non-identifying data for other purposes. We will try our
                        best to isolate your personal data and non-identifying
                        data, and use them separately. If personal data is mixed
                        with non-identifying data, it will still be treated as
                        personal data processing. Without the prior consent of
                        3Vjia, you should not provide us with personal
                        information that 3Vjia does not specifically require and
                        which may result in additional legal obligations for
                        3Vjia outside of this policy.
                      </ListItem>
                    </OrderedList>
                  </ListItem>
                  <ListItem mt={3}>
                    Use of personal dataWe may use your personal data for the
                    following purposes:
                    <OrderedList stylePosition="inside">
                      <ListItem>
                        To help you complete user registration and provide you
                        with products or services;
                      </ListItem>
                      <ListItem>
                        To improve products or services, i.e. evaluate, maintain
                        and improve the products or services operated by us;
                      </ListItem>
                      <ListItem>
                        Such information may be used for security purposes. For
                        example, we may use your information for identity
                        verification, security precautions, archiving and
                        backup, etc., in order to detect, investigate and deal
                        with actions that endanger security, violate laws and
                        regulations, and protect the rights and interests of
                        users;
                      </ListItem>
                      <ListItem>
                        Contact you, send you information about products and
                        services you may be interested in, invite you to
                        participate in 3Vjia promotion activities and market
                        research, or send you marketing information. If you do
                        not want to receive such information, you can choose to
                        unsubscribe;
                      </ListItem>
                      <ListItem>Send you important notifications;</ListItem>
                      <ListItem>
                        Conduct internal audit, data analysis and research to
                        improve our products and services;
                      </ListItem>
                      <ListItem>
                        Comply with and implement applicable laws and
                        regulations, relevant industry standards or our
                        policies;
                      </ListItem>
                      <ListItem>
                        Other situations that require the use of your personal
                        data in order to provide you with services.
                      </ListItem>
                    </OrderedList>
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              How do we disclose your personal data
              <Box fontSize="md">
                Without your prior consent, 3Vjia promises not to sell your
                personal data. 3Vjia will use the personal data you provide in
                accordance with this policy to the extent permitted by
                law.However, 3Vjia may provide your personal data to these
                departments as required by laws and regulations, judiciaries,
                law enforcement agencies, supervisory institution or government
                departments; or disclose it in lawsuits, arbitration and other
                legal or dispute settlement procedures; it may also publicize
                certain personal data to the smallest extent possible when 3Vjia
                have reason to believe that it is necessary to do so to protect
                the interests of 3Vjia, customers or the public. When you
                provide personal data, you understand and agree that 3Vjia will
                use or provide your personal date to third parties in a certain
                circumstances, including but not limited to the following
                situations:
                <OrderedList type="A" styleType="upper-alpha" mt={3}>
                  <ListItem>
                    Disclose to third parties or administrative bodies,
                    judiciaries, law enforcement agencies or supervisory
                    institution in accordance with relevant provisions of laws
                    and regulations, or at the request of administration,
                    judiciary, law enforcement and supervisory institution;
                  </ListItem>
                  <ListItem>
                    If you violate applicable laws, regulations or 3Vjia user's
                    service agreements or relevant policies, it needed to be
                    disclosed to a third party;
                  </ListItem>
                  <ListItem>
                    Disclosure with third parties for the purpose of providing
                    to you or for your use of the products and services ordered
                    or requested by you;
                  </ListItem>
                  <ListItem>
                    Other disclosures considered appropriate by 3Vjia in
                    accordance with laws, regulations or website policies, such
                    as for the purpose of enforcing terms and conditions, or
                    preventing personal or property losses, or illegal acts;
                  </ListItem>
                  <ListItem>
                    Unless authorized by you, or in other circumstances as
                    agreed in this Policy, or because the third party provides
                    services to you as a service
                    provider/subcontractor/authorization centre of 3Vjia, the
                    3Vjia will not provide, sell or lease your personal data to
                    unrelated third parties;
                  </ListItem>
                  <ListItem>
                    For the purpose of serving users, 3Vjia may provide you with
                    information that you are interested in by using your
                    information, including but not limited to sending product
                    and service information to you;
                  </ListItem>
                  <ListItem>
                    In some jurisdictions, if it involves reorganization,
                    merger, bankruptcy or liquidation, you may need to disclose
                    your personal data;
                  </ListItem>
                  <ListItem>
                    Network link information may be used for detection of
                    network attack;
                  </ListItem>
                  <ListItem>
                    In litigation, arbitration and other legal or dispute
                    settlement procedures.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Description of Cookies
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    In order to provide you with functions and services
                    dependent on cookie, or to ensure the normal operation of
                    website, 3Vjia will store, set or access cookie on your
                    computer or mobile device according to business needs.
                    Cookie is plain text file that network server stores on a
                    computer or mobile device. The content of cookie can only be
                    retrieved or read by the server that created it. Each cookie
                    is unique to your web browser or mobile application. Cookies
                    usually contain identifiers, site names, some numbers and
                    characters. With the help of Cookie, the website can store
                    user preference data.
                  </ListItem>
                  <ListItem>
                    We use our cookies, web beacon and other ways to collect
                    information in order to provide you with better service, the
                    main purposes are: to identify and remember your user
                    identity; to preserve user preferences and other information
                    provided to us by you; to analyze your use of our services
                    and your preferences of activities; to optimize advertising
                    in order to deliver more personalized advertisements to you.
                    With Cookie, websites can remember a user's single visit
                    (using session cookie) or multiple visits (using permanent
                    cookie). With Cookie, websites can store settings such as
                    the language, font size and other browsing preferences of
                    computers or mobile devices. This means that users do not
                    need to reconfigure their preferences on every visit. If not
                    using Cookie, website will treat user as a new visitor when
                    every time access a web page. For example, if you log in to
                    a website and go to another page, the website will not
                    recognize you, and you will be logged out again. 3Vjia will
                    not use Cookie for other purposes than those described in
                    this policy.
                  </ListItem>
                  <ListItem>
                    You may reject or manage cookies by modifying your browser
                    settings yourself. If you do not modify the browser settings
                    to reject cookies, you are deemed to agree to accept
                    cookies. You understand and agree that, your refusal to
                    accept cookies may cause you to be unable to log in or use
                    the services or functions of 3Vjia official website that
                    depend on cookies. The consequences will be borne by you and
                    the 3Vjia shall not bear any responsibility.
                  </ListItem>
                  <ListItem>
                    The information obtained through cookies set by 3Vjia will
                    be applicable to the agreement of this policy.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              How does 3Vjia protect your personal data
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    Your personal information collected by us will be kept on
                    the server located in Hong Kong, China. This information and
                    data might be transmitted to your country, region or where
                    information and data are collected by 3Vjia, and it will be
                    accessed, stored and displayed there.
                  </ListItem>
                  <ListItem>
                    We attach importance to the security of personal data. We
                    use appropriate physical, managerial and technical
                    safeguards to protect your personal data from unauthorized
                    access, disclosure, use, modification, damage or loss. For
                    example, we will use encryption technology to ensure data
                    confidentiality; we will use protection mechanisms to
                    prevent malicious attacks on data; we will deploy access
                    control mechanisms to ensure that only authorized person
                    have access for personal data; and we will organize security
                    and privacy protection training courses to enhance
                    employees' awareness of the importance of protecting
                    personal data. We will retain your personal data within the
                    period required to achieve the purpose described in this
                    policy, unless it is necessary to extend retention period or
                    be permitted by law. We will try our best to protect your
                    personal data, but please note that no security measures can
                    be impeccable.
                  </ListItem>
                  <ListItem>
                    Notwithstanding aforementioned security measures, it's your
                    responsibility and obligation to keep the security of your
                    account, password and other information properly. You should
                    be fully responsible for the legal liabilities arising from
                    your failure to keep your account and password.
                  </ListItem>
                  <ListItem>
                    If you find that your account or password has been illegally
                    used or in case of abnormal use, you should notify 3Vjia in
                    time, and you may request 3Vjia to take measures and suspend
                    the login and use of your account.
                  </ListItem>
                  <ListItem>
                    In case of any unfortunate security incidents such as
                    personal information leakage, 3Vjia will inform you and/or
                    competent authorities of the relevant information of data
                    leakage incidents in the form of website announcements or
                    e-mails in accordance with applicable laws and regulations.
                    If your personal information leakage caused by the reasons
                    completely attributable to 3Vjia, 3Vjia will make reasonable
                    efforts to send you a notification of the data leakage
                    without delay after 3Vjia confirms the relevant data leakage
                    events and causes.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              How to access or modify your personal data
              <Box fontSize="md">
                You should ensure that all personal data submitted are accurate.
                3Vjia will try its best to maintain the accuracy and integrity
                of personal data, and update these date in time.In order to
                comply with explicit requirements of applicable low, you may:
                <OrderedList mt={3} stylePosition="inside">
                  <ListItem>
                    have the right to access your specific personal data held by
                    us;
                  </ListItem>
                  <ListItem>
                    require us to update or correct your inaccurate personal
                    data; and
                  </ListItem>
                  <ListItem>
                    we are required delete your personal data in accordance with
                    the law. If you want to exercise the relevant rights, please
                    notify us by email. To ensure safety, you may need to
                    provide written requests. If we have reasonable grounds to
                    believe that these requests are deceptive, unworkable or
                    impair the privacy or rights and interest of others, we will
                    refuse to process them.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Personal Information Protection for Minors
              <Box fontSize="md">
                We attach great importance to the protection of minors'
                information. If you are a minor under the age of 18, it is
                recommended that your guardian read this privacy policy
                carefully, and use our products or services or provide us with
                information upon guardian's consent.
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Third-party social media or other services
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    You can login to our service through third-party social
                    media (Facebook, Google, etc.), and we provide you with the
                    function of sharing content to third-party social media or
                    other services (such as Facebook, Google and other social
                    media and networks); we will also provide you with links
                    through advertising or other ways of our services so that
                    you can access third-party services or other website. The
                    process of using these services may result in your personal
                    information being collected.
                  </ListItem>
                  <ListItem>
                    Since these third-party social media or other services are
                    operated by third parties, when you use services operated by
                    third parties, you need to be bound by terms of service and
                    privacy policy of such third parties. We strongly recommend
                    that you read the terms carefully.
                  </ListItem>
                  <ListItem>
                    This Privacy Policy applies only to the information
                    collected by 3Vjia, and does not apply to services provided
                    by any third party or rules for the use of information
                    provided by any third party. We are not liable for any third
                    party using information provided by you.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Privacy Policy application scope
              <Box fontSize="md">
                This Privacy Policy is a general privacy clause that is
                uniformly used by 3Vjia and applies to all products and services
                of aihouse platform. If certain products or services require
                specific privacy policies, we will explain them to you before
                providing them.This clause does not apply to those products or
                services accessed through our products or services, and
                independently provided to you by third parties, such as third
                part websites linked to our products or services. It is
                important to note that when you use a third party's products or
                services, your information protection should apply to the
                privacy policy of such third party.
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Change and amendment of Privacy Policy
              <Box fontSize="md">
                Our privacy policy may change. We will not restrict your rights
                under this privacy policy without your explicit consent.We will
                also provide conspicuous notifications of major changes to this
                privacy policy (for example, timely notification in the form of
                a pop-up window when modify or upgrade software, or when you
                re-login).Major changes mentioned in this policy, including but
                not limited to:
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    Significant changes have taken place in our service model.
                    For example, the purpose of personal information processing,
                    the type of personal information processing, the way of
                    using personal information, etc;
                  </ListItem>
                  <ListItem>
                    Significant changes have taken place in our control rights.
                    For example, owner changes caused by mergers and
                    acquisitions, reorganization, etc;
                  </ListItem>
                  <ListItem>
                    The main objects of personal information sharing,
                    transferring or publicly disclosing have changed;
                  </ListItem>
                  <ListItem>
                    Significant changes have taken place in your right to
                    participate in personal information processing and in the
                    way you exercise it;
                  </ListItem>
                  <ListItem>
                    When the responsible departments, contact methods and
                    complaint channels for handling personal information
                    security have changed;
                  </ListItem>
                  <ListItem>
                    When personal information security impact assessment report
                    indicates that there is a high risk.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Contact us
              <Box fontSize="md">
                If you have any questions, comments, suggestions or complaints
                about our privacy policy and the handling of your personal
                information, please contact us by email support@aihouse.com. In
                general, we will reply to your request within 15 working days.
              </Box>
            </ListItem>
            <ListItem mt={6}>
              <ListIcon as={BsFillDiamondFill} color="orange" />
              Effectiveness of Privacy Policy
              <Box fontSize="md">
                <OrderedList type="A" styleType="upper-alpha">
                  <ListItem>
                    The Privacy Policy is valid for a long time, from the time
                    you confirm your acceptance or from the time you use service
                    in any way (whichever is earlier).
                  </ListItem>
                  <ListItem>
                    This clause, User Agreement and others shall form an
                    indivisible whole and have the same legal effect. The
                    matters not covered in this clause shall be subject to
                    provisions of User Agreement.
                  </ListItem>
                </OrderedList>
              </Box>
            </ListItem>
          </OrderedList>

          <Text color="gray.400" fontSize="md">
            This privacy policy is issued on January 2, 2019.
          </Text>
        </Stack>
      </Container>
    </Box>
  );
};

export default PrivacyPolicyPage;
